import React from "react";
import { Container } from "react-bootstrap";
import './MentionsLegales.css'; // Assurez-vous d'ajouter cette importation pour le fichier CSS
import Particle from "../Particle";

function MentionsLegales() {
  <Particle />

  return (
    <Container style={{ minHeight: "100vh", paddingTop: "100px" }}>
      <h1 className="titre">Mentions Légales</h1>
      <p>
        <strong className="strong-text">Propriétaire du site :</strong><br />
        <span className="text-white">Kylian PAPEGAY<br />
        Email : kylian.papegay@gmail.com<br />
        </span>
      </p>
      <p>
        <strong className="strong-text">Hébergement :</strong><br />
        <span className="text-white">HolyCloud<br />
        Adresse : 78 Avenue des Champs-Élysées, Bureau 562, 75008 Paris<br />
        Téléphone : 01 76 23 10 11</span>
      </p>
      <p>
        <strong className="strong-text">Données personnelles :</strong><br />
        <span className="text-white">Ce site respecte la confidentialité de vos données. Les informations
        collectées via les formulaires sont utilisées uniquement pour vous
        répondre.</span>
      </p>
      <p>
        <strong className="strong-text">Propriété intellectuelle :</strong><br />
        <span className="text-white">Tous les contenus présents sur ce site (textes, images, vidéos) sont la
        propriété exclusive de Kylian PAPEGAY.</span>
      </p>
    </Container>
  );
}

export default MentionsLegales;
