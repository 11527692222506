import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import PV_Portfolio from "../../Assets/Projects/portfolio.png"
import chatify from "../../Assets/Projects/chatify.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mes <strong className="purple">Projets </strong>
        </h1>
        <p style={{ color: "white" }}>
        Voici quelques projets sur lesquels j'ai travaillé récemment.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="CUB"
              description="CUB est un projet scolaire dont l'objectif était de créer notre propre réseau STORMSHIELD et communiqué avec les réseaux de nos camarades de classe."
              ghLink="https://github.com/Kykyyppg/CUB"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={PV_Portfolio}
              isBlog={false}
              title="Portfolio"
              description="Vous êtes actuellement sur mon portfolio, que dire de plus ? :)"
              demoLink="https://kpapegay.fr"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;