import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Etudiant administrateur systèmes & réseaux",
          "Passionné d'informatique",
          "En recherche d'alternance"
        ],
        autoStart: true,
        loop: true,
        delay: 50,
        deleteSpeed: 40,
      }}
    />
  );
}

export default Type;
