// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.strong-text {
    color: #8500ad; /* Couleur violette */
  }
  
  .text-white {
    color: white; /* Texte en blanc */
  }
  
  .titre {
    color: white; /* Titre en blanc */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Legals/MentionsLegales.css"],"names":[],"mappings":"AAAA;IACI,cAAc,EAAE,qBAAqB;EACvC;;EAEA;IACE,YAAY,EAAE,mBAAmB;EACnC;;EAEA;IACE,YAAY,EAAE,mBAAmB;EACnC","sourcesContent":[".strong-text {\r\n    color: #8500ad; /* Couleur violette */\r\n  }\r\n  \r\n  .text-white {\r\n    color: white; /* Texte en blanc */\r\n  }\r\n  \r\n  .titre {\r\n    color: white; /* Titre en blanc */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
